<template>
    <div class="layout">
        <div class="page-5 page">
            <Header :activeIndex="'2'"></Header>
            <div class="page-section">
				<a @click="handleClick2">
                <div class="section-tab" >
                    <div class="section-center">   
                        <el-tabs style="position: relative;top: 350px;font-size: 26px;margin-left: -130px;" v-model="activeName" type="card" @tab-click="handleClick">                            
							
                        </el-tabs>
                    </div>
                </div></a>
                <div class="section-sub-tab" v-if="activeName=='first'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <h4 class="sec-tit"><i class="el-icon-caret-right"></i><span>地方金融行业垂直模型应用场景</span></h4>
                            <el-tabs v-model="activeName1" type="card" @tab-click="handleClick1">                            
                                <el-tab-pane v-for="(item,index) in subTab1" :key="index" :label="item.name" :name="String(index)">
                                    <div class="flex">
                                        <div class="l">
                                            <div>
                                                <p>{{item.scence}}</p>
                                            </div>
                                            <div>
                                                <p>{{item.value}}</p>
                                            </div>
											<div style="margin-top: 7%;"><p>{{item.value2}}</p></div>
                                        </div>
										<!--
                                        <div class="r">
                                            <img v-if="!(Array.isArray(item.img))" :src="item.img" alt="">
                                            <div v-else>
                                                <el-carousel height="39vh" indicator-position="outside" v-if="activeName1==String(3)">
                                                    <el-carousel-item v-for="(it,index) in item.img" :key="index">
                                                        <img :src="it" alt="">
                                                    </el-carousel-item>
                                                </el-carousel>
                                            </div>
                                        </div>
										-->
                                    </div>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
                <div class="section-sub-tab" v-if="activeName=='second'" id="section-sub-tab">
                    <div class="sec-bot">
                        <div class="section-center">
                            <div style="text-align: center;margin-top: -5.5556vh;">
                                <img src="../../assets/img/page1-5.png" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import { Header, Footer } from "../common/common.js";
import B1 from '../../assets/img/page1-1.png'
import B4 from '../../assets/img/Group17.jpg'

  export default {
    components: {
        Header,
        Footer,
    },
    name: '',
    data () {
      return {
        activeName: 'first',
        subTab: [],
        activeName1: '0',
        subTab1: [
            {
                name: '智能客服',
                scence: '客服智能体：基于行业数据集和客户私域知识库，创建高拟人化客服机器人，可准确识别用户意图，实现精准匹配用户问题。支持自动记录高频问答，实现全自动化运行。',
                value: '智能质检：通过智能分析客服对话内容，对服务过程中的合规性进行自动监督，有效提升服务质量并降低企业成本负担。通过洞察客户意图对客服质量进行动态评价。',
				value2: '客户服务：支持智能纪要，智能代办，智能工单服务。通过语音或文字方式智能生成工单，自动推送到相关部门，并通过短信或邮件通知客户回访，实施全流程自动化运作。',
                img: B4
            },
            {
                name: '营销提效',
                scence: '舆情与市场分析：用于需求分析、市场商业洞察、热点分析等。总结提取市场用户对品牌、产品功能、服务的的评价和观点，为市场决策提供支持。',
                value: '智能营销：通过完善用户画像标签体系，为用户提供个性化服务体验。实现金融产品智能推荐，提升营销效果转化。',
                value2: '营销方案：支持快速生成营销方案，激发创意灵感，提升营销创作效率。',
				img: B1
            },
            {
                name: '风控合规审查',
                scence: '智能审查：通过智能化手段，提升消保审查、公文规范审查等领域的工作效率和准确度。自动化执行相关审查任务，确保合规性。',
                value: '智能报告：辅助自动化生成反洗钱可疑报告、法审报告等规范格式报告，简化繁琐重复的报告撰写工作，提升风控合规审查效率。',
                value2: '舆情监测：构建全天7X24小时的舆情监测网络，实时监测市场动态和风险信号，提升风险预警响应时效。',
				img: B4
            }
        ],
      }
    },
    mounted() {
        this.$nextTick(()=>{
            if(this.$route.query.sec){
                var sec = this.$route.query.sec
                this.activeName = sec
            }
        })
    },
    methods: {
        handleClick() {
			window.open("https://haosuan.com/portal/deepSeekChat/");
        },
        handleClick2() {
			window.open("https://haosuan.com/portal/deepSeekChat/");
			
        }
    }
        

  }
</script>

<style>
.a-btn { 
width:50px;
height:40px;
text-align:center;
line-height:40px;
border-radius:6px;
margin:2.1481vh auto 3.5185vh;
background: #3498ef;font-size: 16px;
}

    .page-5 .page-section .section-tab {  
        background: url('../../assets/img/Group12.jpg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
		height: 800px;
    }
    .page-5 .page-section .section-sub-tab {
        background: url('../../assets/img/Group16.jpg') no-repeat; 
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
        /* padding: 40px 0 60px; */
    }
    .page-5 .page-section .section-sub-tab .sec-bot .r img {
        max-height: 38.6111vh;
    }
    .page-5 .page-section .section-sub-tab .sec-bot .el-tab-pane:nth-of-type(1) .r img {
        max-height: 32.1296vh;
    }

    .page-5 .banner-img-1 {
        height: 48.4259VH;
       /*  background: url('../../assets/img/Maskgroup.jpg') no-repeat; */
        background-size: contain;
        background-position: center;
    }
    .page-5 .banner-img-2 {
        height: 49.8148VH;
        background: url('../../assets/img/page1-banner2.png') no-repeat; 
        background-size: contain;
        background-position: center;
    }
    #pane-3 .l {
        width: 40%;
    }
    #pane-3 .r {
        width: 52%;
    }

    .page-5 .page-section .section-sub-tab .sec-bot .l{
        width: 45%;
        padding: 0;
    }
    .page-5 .page-section .section-sub-tab .sec-bot .r {
        width: 55%;
        max-width: 55%;
        padding: 0;
    }
    .page-5 .page-section .el-carousel__item {
        display: flex;
        align-items: center;
    }

</style>

