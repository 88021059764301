<template>
    <div class="index-page">
        <Header :activeIndex="'1'"></Header>
        <div class="section-A" id="section-A">
            <el-row :gutter="24" style="margin: 0;padding: 0;width:100%;height:100%;">
                <el-col style="margin: 0;padding: 0;">
                    <div class="video-box" @mouseenter="show=true" @mouseleave="show=false" :style="{height: vdaH}">
                        <!-- <el-carousel trigger="click" :height="vdaH" id="el-carousel" indicator-position="none" arrow="never"> -->
                            <!-- <el-carousel-item v-for="(item,index) in 1" :key="index"> -->
                                <div style="width: 100%;height: 100%">
                                    <video id="vid-0" autoplay loop muted>
                                        <!-- <source src="https://h5.gzszxr.cn/stage-api/common/ossDownload?resource=common/video/jinfucloud_index.mp4" type="video/mp4"> -->
										<source src="../assets/mp4/jinfucloud_index.mp4" type="video/mp4">
                                        您的浏览器不支持Video标签。
                                    </video>
                                    <div class="controls-btn" @click="controlBtn(0)" v-if="show">
                                        <el-col v-if="0==0">
                                            <i v-if="!play0" class="el-icon-video-play"></i>
                                            <i v-else class="el-icon-video-pause"></i>
                                        </el-col>
                                        <!-- <el-col v-if="index==1">
                                            <i v-if="!play1" class="el-icon-video-play"></i>
                                            <i v-else class="el-icon-video-pause"></i>
                                        </el-col>
                                        <el-col v-if="index==2">
                                            <i v-if="!play2" class="el-icon-video-play"></i>
                                            <i v-else class="el-icon-video-pause"></i>
                                        </el-col> -->
                                    </div>
                                    <div class="down-icon">
                                        <i class="el-icon-bottom"></i>
                                    </div>
                                </div>
                            <!-- </el-carousel-item> -->
                        <!-- </el-carousel> -->
                    </div>
                </el-col>
            </el-row>
            <div class="down-btn">
                <a href="#section-B"><i class="el-icon-bottom"></i></a>
            </div>
        </div>
        <div class="section-B" id="section-B">
            <el-row :gutter="20">
                <el-col :span="24">
                    <div class="B-content">
                        <el-row :gutter="16">
                            <el-col :span="13" :offset="3">
                                <div class="content">
                                    <h3 class="tit text-c fw-700">平台服务架构</h3>
                                    <p class="desc text-c">
                                        一个为地方金融机构提供数字化转型服务的技术共享平台，为地方金融机构的市场开拓、业务运营、<br />风险管理和数据获取等核心业务提供解决方案、软件系统、计算资源和数据资源服务。
                                    </p>
                                    <div class="platform-box">
                                        <el-row :gutter="24" style="margin: 0;" class="box-A">
                                            <el-col 
                                                v-for="(item, index) in arrA"
                                                :key="index"
                                                class="el-col-span"
                                                :style="item.href==''?'':'cursor:pointer'"
                                            >
                                                <el-card 
                                                    :body-style="{ padding: '0px' }" 
                                                    shadow="never" 
                                                    :style="'background: url('+ item.bg +') no-repeat;background-size: 100%;'"
                                                    @mouseleave="removeClass($event,'mask')" 
                                                    @mouseenter="addClass($event,item.href,'mask')"
                                                >
                                                    <p 
                                                        class="card-tit" 
                                                        v-html="item.desc" 
                                                        @click="openHref(item.href,'out')"
                                                    ></p>
                                                    <div 
                                                        class="mask-box mask"                                            
                                                        @mouseleave="removeClass($event,'mask')" 
                                                        @mouseenter="addClass($event,item.href,'mask')"
                                                        @click="openHref(item.href,'out')"
                                                    ></div>
                                                </el-card>
                                            </el-col>
                                            
                                            <el-col style="width:6%;padding: 0;" >
                                                <el-card :body-style="{ padding: '0px' }" style="margin-right:0;background:#195084;border:none;border-radius:0;" shadow="never">
                                                    <p class="text-more">更<br>多<br>赋<br>能<br>领<br>域</p>
                                                </el-card>
                                            </el-col>
                                        </el-row>
                                        <el-row :gutter="24" style="margin: 0;" class="box-B">
                                            <el-col
                                                v-for="(o, index) in arrB"
                                                :key="index"
                                                :offset="index > 0 ? 0 : 0"
                                                style="padding: 0;"
                                                class="el-col-span"
                                            >
                                                <el-card :body-style="{ padding: '0px' }" style="border-radius:0;margin-right: 0;border: none;height: 2.4375rem;background:transparent;text-align: center;" shadow="never">
                                                    <img style="vertical-align: middle;" src="../assets/img/up.png">
                                                    <span style="font-size: 1.25rem;color: #fff;margin: 0 1rem;vertical-align: middle;">{{o.desc}}</span>
                                                    <img style="vertical-align: middle;" src="../assets/img/up.png">
                                                </el-card>
                                            </el-col>
                                        </el-row>
                                        <el-row :gutter="24" style="margin: 0;" class="box-C">
                                            <el-col 
                                                v-for="(item, index) in arrC"
                                                :key="index"
                                                :span="item.span"
                                                class="el-col-span"
                                            >
                                                <el-card 
                                                    :body-style="{ padding: '0px' }" 
                                                    shadow="never" 
                                                    :style="'background:url('+ item.bg +');background-size: 100% 100%;'"
                                                >                                                   
                                                    <div class="mask-box mask"></div>
                                                    <span class="card-tit" v-html="item.desc" @click="toPath(item.path)"></span>
                                                    <div class="sub-desc">
                                                        <div
                                                            v-for="(i , index) in item.subDesc"
                                                            :key="index"
                                                            @mouseleave="removeClass($event)" 
                                                            @mouseenter="addClass($event,i.href)"
                                                            class="sub-box"
                                                            @click="openHref(i.href,'in',i.sec)"
                                                        >
                                                            <el-col v-if="!(item.desc=='智能引擎层'&&i.text=='计算实验' || item.desc=='计算资源层'&&i.text=='超级计算云')">
                                                                <i class="iconfont" v-html="i.icon"></i>
                                                                <span v-html="i.text"></span>
                                                            </el-col>
                                                            <el-col v-if="item.desc=='智能引擎层'&&i.text=='计算实验' || item.desc=='计算资源层'&&i.text=='超级计算云'" style="padding: 0 !important;">
                                                                <!-- <a 
                                                                    href="https://jy-datav-images.oss-cn-shanghai.aliyuncs.com/jinfucloud/%E8%BF%9C%E7%A8%8B%E8%B6%85%E7%AE%97%E4%B8%AD%E5%BF%83FATE.rdp" 
                                                                    class="hover-a"
                                                                > -->
                                                                    <i class="iconfont" v-html="i.icon"></i>
                                                                    <span v-html="i.text"></span>
                                                                <!-- </a> -->
                                                            </el-col>
                                                        </div>
                                                    </div>
                                                </el-card>
                                            </el-col>
                                            <h3 class="tit text-c fw-700" style="margin: 0;">地方金融数字化基础设施平台</h3>
                                        </el-row>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>                    
                    </div>
                </el-col>    
            </el-row>
        </div>
        <Footer></Footer>
        <!-- <a href="/page1">ttt</a> -->
    </div>
</template>

<script>
import { Header, Footer } from "./common/common.js";
import B1 from '@/assets/img/B-1.png';
// import B2 from '@/assets/img/B-2.png';
import B3 from '@/assets/img/B-3.png';
import B4 from '@/assets/img/B-4.png';
import B5 from '@/assets/img/B-5.png';
import B6 from '@/assets/img/B-6.png';
import B7 from '@/assets/img/B-7.png';
import B8 from '@/assets/img/B-8.png';
import B9 from '@/assets/img/B-9.png';
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            arrA: [
                {
                    desc: '普惠金融',
                    bg: B1,
                    href: ""
                },
                {
                    desc: '公共金融',
                    bg: B5,
                    href: ""
                },
                {
                    desc: '供应链金融',
                    bg: B4,
                    href: ""
                },
                {
                    desc: '行业监管',
                    bg: B3,
                    href: ""
                },
            ],
            arrB: [
                {
                    desc: '数据中台',
                },
                {
                    desc: '业务中台',
                },
                {
                    desc: '技术中台',
                }
            ],
            arrC: [
                {
                    desc: '应用响应层',
                    path: '',
                    subDesc: [{
                        icon: '&#xe620;',
                        text: '智能风控',
                        href: "/page2",
                        sec: 'first'                             
                    },{
                        icon: '&#xe625;',
                        text: '数据资产管理',
                        href: "/page2",
                        sec: 'second'             
                    },{
                        icon: '&#xe609;',
                        text: '数字化作业',
                        href: "/page2",
                        sec: 'third'                                 
                    },{
                        icon: '&#xe623;',
                        text: '数字签章',
                        href: "/page2",
                        sec: 'fourth'                           
                    },{
                        icon: '&#xe631;',
                        text: '司法调解',
						href: "https://tczx.jinfucloud.cn/organization"
						//href: "https://tczx-admin.jinfucloud.cn/adr/#/login?redirect=%2Fdashboard"
						
                    }],
                    bg: B6,
                    href: "",
                    span: 24
                },
                {
                    desc: '智能引擎层',
                    path: '',
                    subDesc: [{
                        icon: '&#xe659;',
                        text: '知识图谱',
                        href: "/page1",
                        sec: 'first'                           
                    },{
                        icon: '&#xe60d;',
                        text: '机器学习',
                        href: "/page1",
                        sec: 'first'                                                  
                    },{
                        icon: '&#xe7a5;',
                        text: '计算实验',
                        href: ""                                            
                    },{
                        icon: '&#xe6db;',
                        text: '智能决策库',
                        href: "/page1",
                        sec: 'second'                      
                    }],
                    bg: B7,
                    href: "",
                    span: 24
                },
                {
                    desc: '数据治理层',
                    path: '',
                    subDesc: [{
                        icon: '&#xe6dc;',
                        text: '数据治理',
                        href: "/page3",
                        sec: 'first'                       
                    },{
                        icon: '&#xe621;',
                        text: '数据资源共享',
                        href: "/page3",
                        sec: 'second'                           
                    },{
                        icon: '&#xe632;',
                        text: '区块链',
                        href: "/page3",
                        sec: 'third'                      
                    },{
                        icon: '&#xe60e;',
                        text: '隐私计算',
                        href: "/page3",
                        sec: 'fourth'                                   
                    }],
                    bg: B8,
                    span: 24
                },
                {
                    desc: '计算资源层',
                    path: '',
                    subDesc: [{
                        icon: '&#xe615;',
                        text: '超级计算云',
                        href: "/page4"
                    },{
                        icon: '&#xe6a7;',
                        text: '数金云',
                        href: "/page5" 
                    }],
                    bg: B9,
                    href: "",
                    span: 24
                }
            ],
            play0: true,
            play1: true,
            play2: true,
            show: true,
            vdaH: '',
            pcH: 0
        };
    },
    mounted() {
        this.$nextTick(()=>{
            this.pcH = document.documentElement.clientHeight || document.body.clientHeight;
            this.vdaH = String(this.pcH);
            this.setSize()
            this.browserRedirect()
            const that = this;
            window.addEventListener('resize',function(){
                that.setSize();
                that.browserRedirect()
            },false)

            var video = $('video')[0];
            $("html").one('touchstart',function(){
                video.play();
            })

        })
    },
    methods: {
		sifa(){
			alert("11");
			window.location.href = 'https://www.example.com'; // 手动设置跳转
		},
        setSize() {
            
            this.pcH = document.documentElement.clientHeight || document.body.clientHeight;
            this.vdaH = String(this.pcH);
            // document.getElementById('el-carousel').style.height = this.vdaH + 'px'
            // document.querySelector('#el-carousel .el-carousel__container').style.height = this.vdaH + 'px'
        },
        removeClass(e,m) {
            if(m){
                e.target.classList.add('mask')
            }else{
                e.target.classList.remove('active-href')
            }
            
        },
        addClass(e,href,m) {
            if(m){
                if(!(href == "")){
                    e.target.classList.remove('mask')
                }   
            }else{
                if(!(href == "")){
                    e.target.classList.add('active-href')
                }
            }
            
        },
        toPath(path) {
            this.$router.push({
                path: path
            })
        },
        openHref(href,link,sec) {
            if(!(href == "")){
                if(link == 'out'){
                    window.open(href)
                }if(href == 'https://tczx.jinfucloud.cn/organization'){
				//}if(href == 'https://tczx-admin.jinfucloud.cn/adr/#/login?redirect=%2Fdashboard'){
                    window.open(href)
                }else{
                    var routerJump = '';
                    if(sec){
                        routerJump = this.$router.resolve({
                            path: href,
                            query: {
                                sec: sec
                            }
                        })
                        window.open(routerJump.href, '_blank')
                    }else{
                        routerJump = this.$router.resolve({
                            path: href
                        })
                        window.open(routerJump.href, '_blank')
                    }
                }
            }
            
        },
        controlBtn(i) {
            var vid = document.getElementById("vid-"+i)
            if(vid.paused == false){
                vid.pause()
                
                if(i == 0) {
                    this.play0 = false
                }else if(i == 1){
                    this.play1 = false
                }else if(i == 2){
                    this.play2 = false
                }
            }else{
                vid.play()
                
                if(i == 0) {
                    this.play0 = true
                }else if(i == 1){
                    this.play1 = true
                }else if(i == 2){
                    this.play2 = true
                }
            }
           
        },
        browserRedirect() {
            var sUserAgent = navigator.userAgent.toLowerCase();
            // alert(sUserAgent)
            
            if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
                //跳转移动端页面
                this.vdaH = '62.5vh'
            } else {
                //跳转pc端页面
                this.vdaH = String(this.pcH) + 'px';
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hide {
    display: none;
}
.index-page {
    /* width: 100%; */
    /* height: 100%; */
    height: 100vh;
    /* height: 86.7592vh; */
}
  .section-A {
    padding: 0;
    margin: 0;
    /* width: 100%; */
    /* height: 100vh; */
    /* min-height: calc(100vh - 346px); */
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
}
.section-A {
    /* width: 100%; */
    /* height: 100%; */
    background: #151e2c;
}
.section-A .video-box {
    width: 100%;
    height: 100%;
    position: relative;
    min-width: 1200px;
}
.video-box {
    position: relative;
    width: 100%;
    height: 100vh;
    color: #fff;
    background-size: 100% 100%;
    overflow: hidden;
}
 video {
     /* width: 100%;
     height: 100%; */
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    /* min-height: 100%; */
    -o-object-fit: fill;
    object-fit: fill;
    width: 100%;
    /* width: auto; */
    height: auto;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}
.controls-btn {
    font-size: 3.125rem;
    color: #fff;
    position: absolute;
    right: 10rem;
    bottom: 2.5rem;
    opacity: 1;
}
.down-icon {
    display: none;
    width: 2.5rem;
    height: 2.5rem;
    position: absolute;
    left: 50%;
    bottom: 9.375rem;
    margin-left: -1.25rem;
    border: 2px solid #fff;
    text-align: center;
    font-size: 2rem;
    line-height: 2.5rem;
    border-radius: 50%;
    color: #fff;
}
.section-B {
    min-width: 1200px;
    margin: 0 auto;
    padding: 3.5rem 0 4rem 0;
    width: 100%;
    /* height: 100%; */
    position: relative;
    background: #151e2c;
}
.B-content {
    width: 100%;
    height: 100%;
    position: relative;
    min-height: 60rem;
}
.section-B .content {
    min-width: 1200px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #151e2c;
}
.tit {
    font-size: 1.5rem;
    color: #fff;
}
.desc {
    margin-bottom: 2.5rem;
    font-size: 1.125rem;
    color: #fff;
}
.text-c {
    text-align: center;
}
.text-more {
    font-size: 0.875rem;
    color: #fff;
    text-align: center;
    /* height: 15.625rem; */
    height: 8.5rem;
    /* line-height: 2.6rem; */
    padding: .5rem 0 0;
    margin:0;
    box-sizing: border-box;
}
.box-A .el-col-span {
    width: 23.5%;
    padding: 0;
}
.el-row {
    margin: 0 !important;
}
.el-col {
    padding: 0 !important;
}
.el-col-span .el-card {
    position: relative;
    border-radius:0;
    margin-right: 2rem;
    border: none;
    /* height: 15.625rem; */
    height: 8.5rem;
    z-index: 3;
}
.el-col-span .el-card .mask-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* cursor: pointer; */
}
.el-col-span .el-card .mask-box.mask {
    background: rgba(0,0,0,.5);
    cursor: initial;
}
.box-A .card-tit {
    font-size: 1.5rem;
    color: #fff;
    width: 100%;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    z-index: 2;
}
.box-B {
    padding: 0 10%;
}
.box-B .el-col-span {
    width: 33%;
}
.box-C {
    padding: 0.625rem 0.625rem;
    border: 1px solid #3781c6;
}
.box-C .el-col-span .el-card {
    margin-right: 0;
    text-align: center;
    background: transparent;
    margin: 0 0 0.625rem 0;
}
.box-C .el-card__body {
    display: flex;
    flex-direction: row;
    background: red;
}
.box-C .card-tit {
    font-size: 1.625rem;
    color: #fff;
    width: 12%;
    display: inline-block;
    line-height: 8.5rem;
    text-align: left;
    /* width: 100%; */
    /* text-align: center; */
    /* margin: 0; */
    position: absolute;
    top: 0;
    left: 2.5%;
    /* transform: translateX(-50%,-50%); */
    z-index: 2;
}
.box-C .el-col-span .el-card .mask-box.mask {
    background: rgba(0,0,0,.63);
}
.box-C .sub-desc {
    font-size: 1.25rem;
    color: #fff;
    width: 80%;
    text-align: center;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 16.5%;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    flex-direction: inherit;
    justify-content: flex-start;
}
.box-C .el-col .sub-desc .sub-box:not(:last-child) {
    margin-right: 1.4rem;
}
.box-C .sub-desc .sub-box {
    padding: 1.25rem 0.9375rem;
    background: rgba(255,255,255,.3);
    border-radius: 0.5rem;
}
.box-C .sub-desc .sub-box .iconfont {
    font-size: 2rem;
    vertical-align: middle;
}
.box-C .sub-desc .sub-box span {
    /* margin: 0.5rem 0 0; */
    vertical-align: middle;
    margin-left: 1rem;
}
.box-C .sub-desc .sub-box.active-href {
    background: #3e91e0;
    cursor: pointer;
}
.box-C .sub-desc .sub-box .hover-a {
    text-decoration:none;
    color: #fff;
    display: block;
    margin: -1.25rem -0.9375rem;
    padding: 1.25rem 0.9375rem;
    border-radius: 0.5rem;
    cursor: pointer;
}
.box-C .sub-desc .sub-box .hover-a:hover {
    background: #3e91e0;
}
.el-carousel {
    width: 100%;
}
#el-carousel .el-carousel__container {
    height: 100% !important;
}

</style>
