<template>
    <div class="layout">
        <div class="page-aboutus page">
            <div class="page-section">
                <div class="section-tab">
                    <div class="section-center">   
                        <div class="section-cont first-section">
                            <div class="banner-txt">
                                <h4 style="margin-top: -40px;margin-left: 35px;">广州市地方金融组织高管测评系统</h4>
                                <p></p>
                                <ul class="flex">
                                    <li class="li" style="height: 220px;width: 140px;margin-left: 40px;"><a style="text-decoration:none;color: inherit;margin-right: 2rem;display: inline-block;" target="_blank" href="https://www.kaoshixing.com/login/account/login/593585">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;融&nbsp;&nbsp;资&nbsp;&nbsp;租&nbsp;&nbsp;赁</a></li>
                                    <li class="li" style="height: 220px;width: 140px;margin-left: 40px;"><a style="text-decoration:none;color: inherit;margin-right: 2rem;display: inline-block;" target="_blank" href="https://www.kaoshixing.com/login/account/login/526839">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;小&nbsp;&nbsp;额&nbsp;&nbsp;贷&nbsp;&nbsp;款</a></li>
                                    <li class="li" style="height: 220px;width: 140px;margin-left: 40px;"><a style="text-decoration:none;color: inherit;margin-right: 2rem;display: inline-block;" target="_blank" href="https://www.kaoshixing.com/login/account/login/602414">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;融&nbsp;&nbsp;资&nbsp;&nbsp;担&nbsp;&nbsp;保</a></li>
									<li class="li" style="height: 220px;width: 140px;margin-left: 40px;"><a style="text-decoration:none;color: inherit;margin-right: 2rem;display: inline-block;" target="_blank" href="https://www.kaoshixing.com/login/account/login/594788">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;商&nbsp;&nbsp;业&nbsp;&nbsp;保&nbsp;&nbsp;理</a></li>
									<li class="li" style="height: 220px;width: 140px;margin-left: 40px;"><a style="text-decoration:none;color: inherit;margin-right: 2rem;display: inline-block;" target="_blank" href="https://www.kaoshixing.com/login/account/login/544001">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;典&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;当</a></li>
                                </ul>  
                                <!-- <i class="down"></i><span style="color: #fff;font-size: 0.7cm;margin-top: 25px;">点击按钮进入相应行业>>></span> -->
                            </div>
                        </div>  
                    </div>  
					<p style="margin: 0 auto;width:50%;margin-top:14%;"><a style="font-size:17px;color:#fff;margin-right: 2rem;display: inline-block;margin-left: 200px;" target="_blank" href="https://www.jinfucloud.cn">广州地方金融数字化基础设施平台      备案号：粤ICP备18146694号</a></p>
                </div> 
            </div>
        </div>
    </div>
</template>

<script>


  export default {
    name: '',
    data () {
      return {
        
      }
    },
    mounted() {
        this.$nextTick(()=>{
            
        })
    },
    methods: {}
        

  }
</script>
<style>
.li {
  writing-mode: vertical-rl; /* 文本从上到下，从右到左 */
  /* 如果需要，可以添加一些样式调整，比如间距等 */
  padding: 5px 10px;
  height: 50px;
  margin: 5px 0;
  margin-top: -15px;
  list-style: none; /* 移除默认列表样式 */
}
  .el-row {
      margin: 0 !important;
  }
  .common-foot  {
      background: #f2f2f2;
      /* margin-top: 0.5rem; */
      min-width: 1200px;
      margin: 0 auto;
  }
  .foot-center {
      width: 1200px;
      margin: 0 auto;
  }
  .p-r {
    position: relative;
    padding-left: 113px;
    line-height: 24px;
  }
  .p-ab {
    position: absolute;
    left: 0;
    text-align: right;
  }
</style>
<style>

    .page-aboutus .page-section .section-tab {        
        /* position: relative; */
        padding: 19.9074vh 0 0;
        background: url('../../assets/img/002.jpg') no-repeat;
        background-size: 100% 100%;
        background-position: center;
        min-width: 1200px;
    }
    .page-aboutus .page-section .banner-txt {
        width: 100%;
        height: 38.4259vh;
        margin: 0 auto 18.9815vh;
        background: rgba(1, 64, 185,.4);
        text-align: center;
        padding: 9.074vh 0 0;
        position: relative;
}
    .page-aboutus .page-section .banner-txt h4 {
        color: #fff;
        font-size: 3.75rem;
        margin: 0;
        font-weight: 900;
    }
    .page-aboutus .page-section .banner-txt p {
        color: #fff;
        font-size: 2.5rem;
        margin: 2vh auto 6.6926vh;
    }
    .page-aboutus .page-section .banner-txt ul {
        justify-content: center;
    }
    .page-aboutus .page-section .banner-txt ul li {
        width: 220px;
        height: 6.2264vh;
        line-height: 6.2264vh;
        font-size: 1.5rem;
        padding-left: 20px;
        box-sizing: border-box;
        text-align: left;
        color: #fff;
        background: linear-gradient(90deg,#3e91e0,rgba(0,0,0,0));
    }
    
    .page-aboutus .page-section .section-sub-tab .sec-bot {
        padding: 7.4074vh 0 14.8148vh;
        /* background: #f8f8f8; */
    }
    .up {
        position: absolute;
        top: 0;
        left: 0;
    }
    .down {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .up:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 25px;
        height: 3px;
        background: #fff;
    }
    .up:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 25px;
        background: #fff;
    }
    .down:before {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 25px;
        height: 3px;
        background: #fff;
    }
    .down:after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3px;
        height: 25px;
        background: #fff;
    }
</style>

